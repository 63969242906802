import React, { useEffect } from 'react';
import Logo from '../../assets/icons/BackgroundLogo';
import Button from '../../components/button/Button';
import LoginText from '../../assets/icons/LoginText';
import LoginArrow from '../../assets/icons/LoginArrow';
import { useMutation } from '@tanstack/react-query';
import { login, loginRequest, registerDiscord, Tokens } from '../../services/auth.service';
import { AxiosError } from 'axios';
import { useMsal } from '@azure/msal-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useNotistack from 'react-helpers/src/hooks/useNotistack';
import { Loader } from 'react-helpers';
import { appUrl } from '../../environments.json';

export type RegisterDiscordForm = {
    type: string;
    token: string;
    discordUserId?: string;
};

const RegisterDiscord = () => {
    const { instance } = useMsal();
    const [searchParams, setSearchParams] = useSearchParams();
    const { showError, showAxiosError } = useNotistack();
    const navigate = useNavigate();
    const { mutate: registerDiscordMutation, isLoading } = useMutation(
        (data: RegisterDiscordForm) => registerDiscord(data),
        {
            onError: (error: AxiosError) => {
                showAxiosError(error);
            },
            onSuccess: () => {
                navigate('/register-discord-success');
            },
        },
    );

    useEffect(() => {
        if (!searchParams.get('discordUserId')) {
            showError(
                'No Discord User detected. Please return to Discord and press the "Link my VITL Home Account!" button in the #welcome channel.',
            );
        }
    }, []);

    const handleLogin = (loginType: 'popup' | 'redirect') => {
        if (loginType === 'popup') {
            instance
                .loginPopup(loginRequest)
                .then((a) =>
                    registerDiscordMutation({
                        type: 'microsoft',
                        token: a.accessToken,
                        discordUserId: searchParams.get('discordUserId'),
                    }),
                )
                .catch((e) => {
                    console.log(e);
                });
        } else if (loginType === 'redirect') {
            instance.loginRedirect(loginRequest).catch((e) => {
                console.log(e);
            });
        }
    };

    if (isLoading) {
        return (
            <div className="text-center m-10">
                Linking your discord account now. This may take around 30 seconds or so. Please do not close this page.
                <Loader />
            </div>
        );
    }
    return (
        <div className="flex w-full h-screen container mx-auto">
            <div className="w-full h-full">
                <div className="absolute top-0 left-0 z-0">
                    <Logo />
                </div>
                <div className="flex flex-col items-center z-10 justify-center gap-10 h-full">
                    <h1 className="font-ivyora text-4xl px-4 text-center">
                        Link your VITL Home account with Discord by logging into your VITL Email below.
                    </h1>
                    <div className="flex flex-col items-center z-10 justify-center">
                        <Button
                            size="large"
                            onClick={() => handleLogin('popup')}
                            variant="primary"
                            className="z-10 text-lg"
                            disabled={!searchParams.get('discordUserId') || isLoading}
                        >
                            Login with a @vitlpower.com email
                        </Button>
                    </div>
                    <div className="flex flex-col items-center justify-center z-10 px-10 underline text-center text-md">
                        <p
                            onClick={() =>
                                navigate(
                                    `/register-discord-magic-link?discordUserId=${searchParams.get('discordUserId')}`,
                                )
                            }
                        >
                            No @vitlpower.com email? Tap here to get a magic link to link your email.
                        </p>
                    </div>
                    <div className="flex flex-col items-center justify-center z-10 px-10 text-center text-sm">
                        <p>
                            Please make sure to use your full name in Discord. If you have an existing Discord account,
                            please change your server nickname to your full name. For help, refer to{' '}
                            <a
                                href="https://vitl-power.gitbook.io/vitl-discord-docs"
                                className="underline"
                                target="_blank"
                            >
                                these docs on how to make a Discord account and how to change your nickname.
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterDiscord;

import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { Formik, Form } from 'formik';
import { FormikVitlTextField, SubmitButton } from 'react-helpers/src/components/forms';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import useNotistack from 'react-helpers/src/hooks/useNotistack';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { createDiscordMagicLink } from '../../services/auth.service';
import Button from '../../components/button/Button';

const RegisterDiscordMagicLink = () => {
    const navigate = useNavigate();
    const { showAxiosError, showSuccess } = useNotistack();
    const [searchParams] = useSearchParams();
    const { mutate: createDiscordMagicLinkMutation, isLoading } = useMutation(createDiscordMagicLink, {
        onSuccess: () => {
            showSuccess('A magic link has been sent to your email.');
        },
        onError: (err: AxiosError) => {
            showAxiosError(err);
        },
    });
    return (
        <Container maxWidth="md" disableGutters>
            <Box p={4} mt={'20vh'}>
                <Box my={2}>
                    <p className="font-ivyora text-xl font-bold">
                        Create your magic link to connect to Discord using your non-"@vitlpower.com" email. This email
                        must belong to a valid user within our system or you will not receive a magic link. 🪄 Magic
                        Links are valid for 1 day.
                    </p>
                </Box>
                <Formik
                    initialValues={{
                        email: '',
                        referrer: 'crm',
                    }}
                    onSubmit={(values) => {
                        createDiscordMagicLinkMutation({
                            email: values.email,
                            discordUserId: searchParams.get('discordUserId'),
                            referrer: 'root',
                        });
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <FormikVitlTextField
                                sx={{ mt: 1 }}
                                name="email"
                                label="Email"
                                value={values.email}
                                onChange={(e) => {
                                    setFieldValue('email', e.target.value);
                                }}
                            />
                            <div className="flex flex-col items-center z-10 h-full mt-10">
                                <Button size="large" disabled={isLoading}>
                                    Create Magic Link
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div className="text-center mt-20">
                    <p
                        className="underline"
                        onClick={() => navigate(`/register-discord?discordUserId=${searchParams.get('discordUserId')}`)}
                    >
                        Link Discord to @vitlpower.com email instead
                    </p>
                </div>
            </Box>
        </Container>
    );
};

export default RegisterDiscordMagicLink;
function showSuccess(arg0: string) {
    throw new Error('Function not implemented.');
}

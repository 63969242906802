import React, { useEffect } from 'react';
import Logo from '../../assets/icons/BackgroundLogo';
import Button from '../../components/button/Button';
import LoginText from '../../assets/icons/LoginText';
import LoginArrow from '../../assets/icons/LoginArrow';

const RegisterDiscordSuccess = () => {
    return (
        <div className="flex w-full h-screen container mx-auto">
            <div className="w-full h-full">
                <div className="absolute top-0 left-0 z-0">
                    <Logo />
                </div>
                <div className="flex flex-col items-center z-10 justify-center space-y-20 h-full">
                    <h1 className="font-ivyora text-4xl px-4 text-center">
                        Your Discord account has been linked! You can now close this window.
                    </h1>
                    <h2 className="font-ivyora text-xl">#uncommonenergy</h2>
                </div>
            </div>
        </div>
    );
};

export default RegisterDiscordSuccess;

import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import useNotistack from 'react-helpers/src/hooks/useNotistack';
import { linkDiscordWithMagicLink, Tokens } from '../../services/auth.service';
import { AxiosError } from 'axios';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import VitlIcon from '../../assets/icons/vitl-icon.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loader } from 'react-helpers';

const LinkDiscordWithMagicLink = () => {
    const { showAxiosError, showSuccess, showError } = useNotistack();
    const navigate = useNavigate();
    const { mutate, isLoading } = useMutation(linkDiscordWithMagicLink, {
        onSuccess: () => {
            navigate('/register-discord-success');
        },
        onError: (err: AxiosError) => {
            showAxiosError(err);
        },
    });
    const [searchParams, _] = useSearchParams();

    useEffect(() => {
        const email = searchParams.get('email');
        const token = searchParams.get('token');
        const discordUserId = searchParams.get('discordUserId');
        if (!email || !token || !discordUserId) return;
        mutate({
            email,
            token,
            discordUserId,
        });
    }, []);

    return (
        <Container maxWidth="md">
            <Box display="flex" alignItems="center" flexDirection="column" gap={4} mt={'20vh'}>
                {isLoading && (
                    <div className="text-center m-10">
                        Linking your discord account now. This may take around 30 seconds or so. Please do not close
                        this page.
                        <Loader />
                    </div>
                )}
            </Box>
        </Container>
    );
};

export default LinkDiscordWithMagicLink;

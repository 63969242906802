/// <reference path="../../../index.d.ts" />
import { useMsal } from '@azure/msal-react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React from 'react';
import useNotistack from 'react-helpers/src/hooks/useNotistack';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import AerialNeighborhoodImage from '../../assets/images/aerial-neighborhood.jpg';
import { Tokens, login, loginRequest } from '../../services/auth.service';
import Button from '../../components/button/Button';
import LoginArrow from '../../assets/icons/LoginArrow';
import LoginText from '../../assets/icons/LoginText';
import Logo from '../../assets/icons/BackgroundLogo';

export type LoginForm = {
    type: string;
    token: string;
};

const Login = () => {
    const { instance } = useMsal();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginForm>();
    const { showAxiosError } = useNotistack();
    const navigate = useNavigate();

    const { mutate: loginMutation, isLoading } = useMutation((data: LoginForm) => login(data), {
        onError: (error: AxiosError) => {
            showAxiosError(error);
        },
        onSuccess: (response: Tokens) => {
            console.log(response);
            localStorage.setItem('token', response.accessToken);
            localStorage.setItem('refreshToken', response.refreshToken);
            sessionStorage.setItem('token', response.accessToken);
            sessionStorage.setItem('refreshToken', response.refreshToken);
            if (window.location.search.includes('referrer')) {
                window.location.href = `${window.location.search.split('=')[1]}?token=${
                    response.accessToken
                }&refreshToken=${response.refreshToken}`;
            } else navigate('/');
        },
    });

    const handleLogin = (loginType: 'popup' | 'redirect') => {
        if (loginType === 'popup') {
            instance
                .loginPopup(loginRequest)
                .then((a) =>
                    loginMutation({
                        type: 'microsoft',
                        token: a.accessToken,
                    }),
                )
                .catch((e) => {
                    console.log(e);
                });
        } else if (loginType === 'redirect') {
            instance.loginRedirect(loginRequest).catch((e) => {
                console.log(e);
            });
        }
    };

    return (
        <div className="flex w-full h-screen">
            <div className="w-full lg:w-5/12 xl:w-4/12 h-full">
                <div className="absolute top-0 left-0 z-0">
                    <Logo />
                </div>
                <div className="flex flex-col items-center z-10 justify-center space-y-40 h-full">
                    <h1 className="font-ivyora text-4xl">VITL Home</h1>
                    <Button
                        size="login"
                        onClick={() => handleLogin('popup')}
                        icon={<LoginArrow />}
                        iconPosition="trailing"
                        className="z-10"
                    >
                        <LoginText />
                    </Button>
                    <h2 className="font-ivyora text-xl">#uncommonenergy</h2>
                </div>
            </div>
            <div className="hidden lg:block lg:w-7/12 xl:w-8/12">
                <div
                    className="w-full h-screen bg-cover bg-center"
                    style={{ backgroundImage: `url(${AerialNeighborhoodImage})` }}
                ></div>
            </div>
        </div>
    );
};

export default Login;
